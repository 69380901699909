import request from '@/utils/request'

/**
 * 获取所有角色
 */
export const roleList = data => {
  return request({
    url: '/manager/role',
    params: data
  })
}

/**
 * 创建角色
 */
export const addRole = data => {
  return request({
    url: '/manager/role',
    method: 'POST',
    data
  })
}

/**
 * 更新角色
 */
export const updateRoler = data => {
  return request({
    url: '/manager/role',
    method: 'PUT',
    data
  })
}

/**
 * 删除角色
 */
export const deleteRole = ids => {
  return request({
    url: '/manager/role',
    method: 'DELETE',
    data: ids
  })
}

/**
 * 获取角色详情
 */
export const roleDetail = id => {
  return request({
    url: `/manager/user/${id}`
  })
}

/**
 * 获取指定角色的目录权限列表
 */
export const rolePermission = roleId => {
  return request({
    url: `/manager/menuRole?roleIds=${roleId}`
  })
}

/**
 * 角色绑定目录
 */
export const distributePermission = data => {
  return request({
    url: '/manager/menuRole',
    method: 'POST',
    data
  })
}

/**
 * 按照角色移除目录
 */
export const deletePermission = roleId => {
  return request({
    url: `/manager/menuRole/${roleId}`,
    method: 'DELETE'
  })
}
