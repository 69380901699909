import _ from 'lodash'

/**
 * 数组转为树结构
 * @param {*} lists 数据
 * @param {*} sortName 排序名称
 * @param {*} order 排序方式
 * @param {*} prefixS 前缀
 * @param {*} pidKey 父级ID的key
 * @returns
 */
export const dataToTree = (
  lists = [],
  sortName = '',
  order = 'asc',
  prefixS = '',
  pidKey = 'parentId',
  isOpen = false
) => {
  const res = []
  const map = {}

  // 判断对象是否有某个属性
  const getHasOwnProperty = (obj, property) =>
    Object.prototype.hasOwnProperty.call(obj, property)

  // 边做map存储，边找对应关系
  if (sortName !== '') {
    // 开始进行排序
    lists.sort((pre, next) => pre[sortName] - next[sortName])
    if (order === 'desc') lists.reverse()
  }
  for (const i of lists) {
    i.title = i.name
    i.label = i.name
    i.key = i.id
    i.value = i.id
    map[i.id] = {
      ...i,
      children: getHasOwnProperty(map, i.id) ? map[i.id].children : []
    }
    const newItem = map[i.id]
    /* if (isOpen) {
      newItem.children.push(...handleExpand(i));
    } */
    if (i[pidKey] === 0) {
      res.push(newItem)
    } else {
      if (!getHasOwnProperty(map, i[pidKey])) {
        map[i[pidKey]] = {
          children: []
        }
      }
      map[i[pidKey]].children.push(newItem)
    }
  }

  const handle = async (list, prefix) => {
    return await list.map(item => {
      if (!_.isEmpty(prefix)) {
        item.name = `${prefix}${item.name}`
        item.title = `${prefix}${item.title}`
      }
      if (!item.children.length) {
        // item.children = undefined;
        delete item.children
      } else {
        item = handle(item.children, `${prefix}${_.isEmpty(prefix) ? '' : '-'}`)
      }
      return item
    })
  }

  handle(res, prefixS)
  return res
}

/**
 * 树结构数组扁平化
 * @param {*} data 树结构的数组
 * @param {*} childrenKey 子项key
 * @returns
 */
export function treeToArray(data, childrenKey = 'children') {
  return data.reduce((pre, cur) => {
    const { [childrenKey]: children = [], ...item } = cur
    return pre.concat([{ ...item }], treeToArray(children))
  }, [])
}
