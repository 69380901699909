<template>
  <el-dialog
    :title="title"
    :model-value="modelValue"
    width="600px"
    @close="closed"
  >
    <div class="news-create-container">
      <el-form
        ref="ruleFormRef"
        style="flex: 2"
        :model="form"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="角色key" prop="roleName">
          <el-input v-model="form.roleName"></el-input>
        </el-form-item>

        <el-form-item label="启用">
          <el-radio-group v-model="form.isAlive">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>

    <el-form :model="form" label-width="120px">
      <el-form-item>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="onSubmit"
          >确认</el-button
        >
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch, computed } from 'vue'
import { addRole, updateRoler } from '@/api/role.js'
import { ElMessage } from 'element-plus'
import _ from 'lodash'

const props = defineProps({
  modelValue: {
    type: Boolean,
    require: true
  },
  info: {
    type: Object
  }
})

const emits = defineEmits(['update:modelValue', 'updateClient'])

// 判断是否有参数传递过来
// const isInfoFlag = computed(() => {
//   return JSON.stringify(props.info) === '{}'
// })

// 标题
const title = computed(() => {
  return `${JSON.stringify(props.info) === '{}' ? '创建' : '编辑'}角色`
})

// 数据源
const form = ref({
  isAlive: 0,
  name: '',
  roleName: ''
})

const ruleFormRef = ref(null)

// 取消
const closed = () => {
  form.value = {
    isAlive: 0,
    name: '',
    roleName: ''
  }
  emits('update:modelValue', false)
}

// 提交事件
const submitLoading = ref(false)
const onSubmit = async () => {
  await ruleFormRef.value.validate(async (valid, fields) => {
    if (valid) {
      submitLoading.value = true
      let param = _.cloneDeep(form.value)
      if (!form.value.password) {
        param = _.omit(param, ['password'])
      }
      try {
        _.has(param, 'id') ? await updateRoler(param) : await addRole(param)
        ElMessage.success('更新成功')
        // emits('updateClient', param)
        emits('updateClient')
        closed()
      } finally {
        submitLoading.value = false
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

// 获取数据方法
const loading = ref(false)
const getDetail = async () => {
  loading.value = true
  try {
    form.value = JSON.parse(JSON.stringify(props.info))
  } finally {
    loading.value = false
  }
}
watch(
  () => props.info,
  val => {
    if (JSON.stringify(val) !== '{}') getDetail()
  }
)

const rules = {
  name: [
    {
      required: true,
      message: '请输入角色名称',
      trigger: 'blur'
    }
  ],
  roleName: [
    {
      required: true,
      message: '请填写角色key',
      trigger: 'blur'
    }
  ]
}
</script>

<style lang="scss" scoped>
.news-create-container {
  display: flex;
  ::v-deep .el-form {
    flex: 1;
  }
}
.avatar-uploader ::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
  line-height: 178px;
}
::v-deep .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.avatar-success-container {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  width: 178px;
  height: 178px;
  position: relative;
  font-size: 0;

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
    border-radius: 6px;
  }
  .del-img {
    position: absolute;
    bottom: 0;
    width: 178px;
    height: 30px;
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
}
.photo-container {
  padding-left: 37px;
  .avatar-uploader ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader ::v-deep .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
    line-height: 178px;
  }
  ::v-deep .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatar-success-container {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 178px;
    height: 178px;
    position: relative;
    font-size: 0;

    .avatar {
      width: 178px;
      height: 178px;
      display: block;
      border-radius: 6px;
    }
    .del-img {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30px;
      background: rgba(0, 0, 0, 0.2);
      text-align: center;
      line-height: 30px;
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>
