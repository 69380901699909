import request from '@/utils/request'

/**
 * 获取所有目录权限
 */
export const permissionList = data => {
  return request({
    url: '/manager/menu',
    params: data
  })
}

/**
 * 创建
 */
export const addPermission = data => {
  return request({
    url: '/manager/menu',
    method: 'POST',
    data
  })
}

/**
 * 更新
 */
export const updatePermission = data => {
  return request({
    url: '/manager/menu',
    method: 'PUT',
    data
  })
}

/**
 * 删除指定数据
 */
export const deletePermission = ids => {
  return request({
    url: '/manager/menu',
    method: 'DELETE',
    data: ids
  })
}

/**
 * 获取用户详情
 */
export const permissionDetail = id => {
  return request({
    url: `/manager/menu/${id}`
  })
}
