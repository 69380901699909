<template>
  <el-dialog title="配置角色" :model-value="modelValue" @close="closed">
    <el-tree
      ref="treeRef"
      :data="allPermission"
      show-checkbox
      check-strictly
      node-key="id"
      :props="defaultProps"
      :default-expanded-keys="defaultExpandedKeys"
    >
    </el-tree>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onConfirm">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from 'vue'
import { permissionList } from '@/api/permission'
import { rolePermission, distributePermission } from '@/api/role'
import { ElMessage } from 'element-plus'
import { dataToTree } from '@/utils/tools.js'
import dayjs from 'dayjs'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  roleId: {
    type: [String, Number],
    required: true
  }
})
const emits = defineEmits(['update:modelValue'])

// 所有权限
const allPermission = ref([])
const getPermissionList = async () => {
  const list = await permissionList({
    isDeleted: 0,
    _t: dayjs().valueOf()
  })
  allPermission.value = dataToTree(list)
}
getPermissionList()

// 属性结构配置
const defaultProps = {
  children: 'children',
  label: 'label'
}

// tree 节点
const treeRef = ref(null)

const defaultExpandedKeys = ref([])
// 获取当前用户角色的权限
const getRolePermission = async () => {
  const list = await rolePermission(props.roleId)
  const checkedKeys = list.map(item => item.id)
  treeRef.value.setCheckedKeys(checkedKeys)
  defaultExpandedKeys.value = checkedKeys
}

watch(
  () => props.roleId,
  val => {
    if (val) {
      getRolePermission()
    } else {
      const nodes = treeRef.value.store._getAllNodes()
      nodes.forEach(item => {
        item.expanded = false
      })
    }
  }
)

/**
  确定按钮点击事件
 */
const onConfirm = async () => {
  await distributePermission({
    ids: treeRef.value.getCheckedKeys(),
    roleId: props.roleId
  })
  ElMessage.success('用户角色更新成功')
  closed()
}

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}
</script>

<style lang="scss" scoped></style>
