<template>
  <div class="">
    <el-card class="header">
      <search @search="searchClick" @clear="clearClick"></search>
    </el-card>
    <el-card>
      <div class="table-head-box">
        <el-button
          v-permission="['addRole']"
          class="button"
          type="primary"
          size="small"
          @click="handleCreate"
        >
          新增
        </el-button>
        <el-button
          v-permission="['deleteRole']"
          class="button"
          type="danger"
          size="small"
          :disabled="!selectedIndexs.length"
          @click="handleAllDelete"
        >
          批量删除
        </el-button>
      </div>

      <el-table
        ref="multipleTable"
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="ID" prop="id" width="80"> </el-table-column>
        <el-table-column label="角色名称" prop="name"> </el-table-column>
        <el-table-column label="角色key" prop="roleName"> </el-table-column>
        <el-table-column label="状态" width="100">
          <template #default="{ row }">
            <el-tag :type="row.isAlive ? 'success' : 'danger'">
              {{ row.isAlive === 1 ? '启用' : '停用' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          prop="action"
          width="260"
          #default="{ row }"
        >
          <el-button
            v-permission="['distributePermission']"
            type="primary"
            size="small"
            @click="onDistributePermissionClick(row)"
          >
            权限
          </el-button>
          <el-button
            v-permission="['editRole']"
            type="primary"
            size="small"
            @click="handleUpdate(row)"
          >
            编辑
          </el-button>
          <el-button
            v-permission="['deleteRole']"
            type="danger"
            size="small"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[2, 5, 10, 20, 50, 100, 500, 1000]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <distribute-permission
      v-model="distributePermissionVisible"
      :roleId="selectRoleId"
    ></distribute-permission>

    <create-dialog
      v-model="createDialogVisible"
      :info="editSelectClient"
      @updateClient="getListData"
    ></create-dialog>
  </div>
</template>

<script setup>
import { roleList, deleteRole } from '@/api/role'
import { ref, watch } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import DistributePermission from './components/DistributePermission.vue'
import search from './components/search.vue'
import CreateDialog from './components/create.vue'

// 数据相关
const tableData = ref([])
const multipleTable = ref(null)
const total = ref(0)
const page = ref(1)
const size = ref(10)
const searchFrom = ref({})
const loading = ref(false)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await roleList({
    current: page.value,
    size: size.value,
    ...Object.fromEntries(
      Object.entries(searchFrom.value).filter(
        item => item[1] !== '' || item[1] === 0
      )
    )
  })
  tableData.value = result.items
  total.value = result.total
  loading.value = false
}
getListData()

const selectedIndexs = ref([])
const handleSelectionChange = e => {
  selectedIndexs.value = e
}

// 新增点击事件
const createDialogVisible = ref(false)
const handleCreate = () => {
  createDialogVisible.value = true
}
// 修改点击事件
const editSelectClient = ref({})
const handleUpdate = row => {
  editSelectClient.value = row
  createDialogVisible.value = true
}

/**
 * 处理删除
 */
const handleDelete = row => {
  ElMessageBox.confirm('确定要删除 ' + row.name + ' 吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    deleteFn([row.id])
  })
}
/**
 * 批量删除
 */
const handleAllDelete = () => {
  ElMessageBox.confirm('是否批量删除此信息吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    const ids = selectedIndexs.value.map(item => item.id)
    deleteFn(ids)
  })
}
/**
 * 处理删除请求
 */
const deleteFn = async ids => {
  try {
    await deleteRole(ids)
    ElMessage.success('删除成功')
    // 重新渲染数据
    getListData()
    multipleTable.value.clearSelection()
  } catch (error) {}
}
watch(createDialogVisible, val => {
  if (!val) editSelectClient.value = {}
})

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}

// 搜索
const searchClick = e => {
  page.value = 1
  searchFrom.value = e
  getListData()
}
// 清空
const clearClick = () => {
  page.value = 1
  searchFrom.value = {}
  getListData()
}

/**
 * 分配权限
 */
const distributePermissionVisible = ref(false)
const selectRoleId = ref('')
const onDistributePermissionClick = row => {
  selectRoleId.value = row.id
  distributePermissionVisible.value = true
}

// 保证每次打开重新获取用户角色数据
watch(distributePermissionVisible, val => {
  if (!val) selectRoleId.value = ''
})
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 22px;
}
.pagination {
  margin-top: 20px;
  text-align: center;
}
.table-head-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 14px;
  .button {
    margin: 0 5px;
  }
}
</style>
